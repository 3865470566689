import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import ScrollspyNav from 'react-scrollspy-nav';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';

const NikkeGuidesSakuraSummer: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Sakura: Bloom in Summer guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_sakurasummer.webp"
            alt="Guide"
          />
        </div>
        <div className="page-details">
          <h1>Sakura: Bloom in Summer guide & review</h1>
          <h2>
            A guide & review for Sakura: Bloom in Summer in NIKKE: Goddess of
            Victory.
          </h2>
          <p>
            Last updated: <strong>15/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <div className="guide-with-columns">
          <div className="left-content">
            <div className="section-scroll" id="section-0">
              <SectionHeader title="Sakura: Bloom in Summer" />
              <div className="employee-container for-nikke">
                <NikkeCharacter
                  mode="icon"
                  slug="sakura-bloom-in-summer"
                  enablePopover
                />
              </div>
              <p>
                The summer version of our charismatic Underworld Queens,
                heralded by the vivacious head of Seimeikai, is making their
                second debut in the tropical islands! While the scorching heat
                and blistering sands may be a deterrent, nothing compares to a
                tranquil oasis off the urban haze. Sakura is enlivening the
                coast with newfound look and armament, allowing her to become
                just the formidable DPS that applies serious debuffs, dealing
                sustained damage to her opposition through breaking parts and
                whatnot.
              </p>
              <h5>Can you give a brief summary about this unit?</h5>
              <p>
                Sakura's summer version, or S. Sakura, is reborn as a DPS unit
                instead of her Support roots. Her main focus will be on Solo
                Bosses, since she gets worse if the number of enemies is high.
                Against Bosses, she gets better when the boss has regenerating
                parts. If the Element is in her favor as well, she can outdamage
                most meta units if she comes properly invested, even against the
                mighty Modernia! In neutral conditions, however, she performs at
                the level of Scarlet, except just slightly better and without
                the threat of HP drain. This makes S. Sakura one of the top
                picks for Solo Raids, but that's not all! Her performance in PvP
                is not to be underestimated, as she can be used to wipe enemy
                units as a nuker! Beware of Noah, however, since she can
                completely neutralize S. Sakura!
              </p>
              <h5>Should I pull this unit?</h5>
              <p>
                We believe in a simple: Yes! We are at a point in Nikke where
                Elemental Advantage in Solo Raid is becoming more and more
                important. Teams are becoming more coherent. We have seen some
                prominent DPS units dropping out and coming back in depending on
                the element of the boss! An example of this is none other than
                Scarlet, who now is a shadow of her once dominant former self,
                but still always has a spot whenever there is an Electric-Weak
                raid. Similarly, we do not have a lot of Wind DPS.
                Realistically, we only have Scarlet: Black Shadow, and Guilty if
                you want to be generous. The remaining slots are filled with the
                usual DPS like RH, Alice, Modernia etc. But now, S. Sakura can
                outdamage some prominent units, and thus will come into play
                against Wind Weak Solo Raid Bosses! Rest assured, when properly
                built, she will display exceptional results.
              </p>
              <p>
                Pull a copy if you're new and lack a DPS unit for raids. Pull a
                copy if you are an old player since we're moving towards
                Element-based meta! One copy should suffice if you want to have
                an easier time aiming for higher rankings in Raids. If you main
                campaign and do not want to focus on Raids and PvP, you may skip
                her. As for duplicates, first and foremost, we must praise how
                good looking she and her MLB lobby screen are! Other reasons to
                go for dupes include aiming for Top 200 in Solo Raids, or if you
                take PvP seriously, since dupes contribute a lot to CP.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-1">
              <SectionHeader title="Kit Analysis" />
              <StaticImage
                src="../../../images/nikke/reviews/skills_sakura.webp"
                alt="Kit"
              />
              <p>
                Sakura is a sustained damage dealer who blights enemies with her
                trademark Sakura Petals, a DoT debuff that drains HP every
                second. Sakura automatically applies this debuff to the highest
                ATK enemy every 30 seconds and also boosts her ATK damage at the
                same time. Moreover, if she or an ally destroys a boss's part,
                the duration of this buff and debuff is extended, prolonging the
                mayhem they bring about.
              </p>
              <blockquote>
                <h6>
                  <FontAwesomeIcon icon={faLightbulb} width="18" /> What is
                  Damage-Over-Time (DoT) debuff?
                </h6>
                <p>
                  Entities afflicted with DoT will take a certain amount of
                  damage every second until the debuff timer runs out. The
                  damage per second is based on the ATK value of the unit
                  inflicting it in real time and updates throughout its
                  duration. Providing damage-enhancing buffs after it is applied
                  does wonders.
                </p>
                <p>
                  DoT won't be increased by Bonus Range or Core Hit (obviously),
                  but CRIT, Full Burst Bonus, Elemental DMG, Attack Damage, ATK
                  Buffs, and Damage Taken all do increase its effectiveness.
                  Since DoT is a debuff, it can therefore be cleansed as well.
                  It works similar to Nihilister's. This information can be
                  useful for Arena if you plan to use her there (or fight
                  against her).
                </p>
              </blockquote>
              <p>
                If we take her percentages at face value, she has a decent
                output, rivalling that of Modernia but slightly lower if enemy
                presents favorable conditions to Modernia (core, etc). Most of
                her damage comes from Skill 2 and Burst Skill. This means she is
                somewhat bursty, and her damage would see a significant jump
                after using Burst Skill. However, it is not as apparent as that
                from Red Hood or Alice. Her Skill 2 will deal continuous damage
                as long as the team continues to break parts, while her Burst
                Skill is not as reliant.
              </p>
              <Alert variant="primary">
                <p>
                  If her S2 triggers off CD when the remaining buff/debuff
                  duration is still above 15s, then the original duration will
                  be kept. Otherwise, the duration is restored to 15 seconds.
                </p>
              </Alert>
              <p>
                However, wielding an Assault Rifle, Sakura may not be the best
                when it comes to breaking miniature parts herself. Against
                Mother Whale's, for example, she would have a hard time
                destroying them. On the other hand, cracking gigantic parts like
                Alteisen's in close range would be effortless. Hence, she may
                require another DPS to help destroy parts faster.
              </p>
              <p>
                Sakura's kit boasts beefy numbers. Huge multipliers. Her Burst
                Skill alone deals a total of X,XXX% of caster's ATK as damage in
                total against single targets, which is incredibly high! This
                also does not factor in her Skill 2, which deals a lot of
                continuous damage. Her percentages are indeed very promising!
              </p>
              <p>
                Since Sakura's kit boasts astronomical numbers yet lacks ATK
                buffs, she scales well with them, allowing her performance to
                skyrocket in the right setup. That means, when obtaining OL or
                pairing supports, prioritize ATK buffs more than anything else.
                You also ideally want ATK buffs that are long so that you will
                not have to worry about S2 timings. Another good thing about her
                is that she is not reliant on Max Ammo as most DPS are
                (everything is tied to part breaking).
              </p>
              <Alert variant="primary">
                <p>
                  Elemental DMG is a nice sidegrade to ATK and may be a better
                  pick when preparing her for Iron bosses (aka Golden Kraken,
                  next Solo Raid).
                </p>
              </Alert>
              <StaticImage
                src="../../../images/nikke/reviews/skills_ros.webp"
                alt="Kit"
              />
              <p>
                Just from the kits themselves, we would highly recommend pairing
                her with Rosanna: Chic Ocean. She offers consistent ATK buffs
                and Parts Damage boost that would help Sakura trigger her S2
                more often. If no Rosanna, you can explore other options like
                Tia & Naga or Blanc & Noir. The best option is obviously Crown.
                However, using Rosanna: Chic Ocean offers a plethora of
                advantages, such as making the other supports available for
                other units. A unit should not be compared with the most optimal
                supports but rather the support pairings they usually get.
              </p>
              <Alert variant="primary">
                <p>
                  Although Rosanna is a universal support that boasts a terrific
                  Damage Taken debuff, her Sustained Damage buffs would only
                  work with one meta DPS, and that is Sakura herself. Tell us
                  how to make a somewhat inseparable duo without making it an
                  obvious one.
                </p>
              </Alert>
              <StaticImage
                src="../../../images/nikke/reviews/sakura_1.webp"
                alt="Kit"
              />
              <p>
                It is also important to understand that her Burst Skill deals
                damage sequentially to a random enemy 10 times within 2.2s
                (0.22s/hit). This means it reapplies DoT from time to time,
                increasing its debuff stacks, maxing at 10. As it is
                stack-based, and stacks are NOT independent, her Burst Skill's
                damage will be significantly higher against single targets. This
                is because, if there are multiple enemies, her Burst Skill will
                instead cycle through enemies before going back to the primary
                target. Timers are also refreshed, meaning that her DoT actually
                lasts around 12.2s as the final refresh occurs at 2.2s after
                Full Burst begins. If there are less than 10 enemy units, let's
                say 5 units in PvP, the nuke attacks them in a random order, and
                then the units are hit again in the same order as before.
              </p>
              <Alert variant="primary">
                <p>
                  If you notice, the peak damage of her Burst Skill is not right
                  after Full Burst begins but is slightly delayed as stacks need
                  to be built up. This results in the theoretical assumption
                  that shorter buffs like Liter's would be less efficient on
                  Sakura. A similar complication also affects her S2. Sakura is
                  better coupled with supports that give long-lasting buffs.
                </p>
              </Alert>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-2">
              <SectionHeader title="Tier List" />
              <h5>
                Campaign (LD) - <strong className="a">A</strong> | Campaign (HD)
                - <strong className="b">B</strong>
              </h5>
              <p>
                S. Sakura faces a big problem in campaign-type stages, something
                we have witnessed before with Scarlet: Black Shadow. Her damage
                that comes from her burst, which can stack DoT if it hits the
                same enemy, is divided into 10. It tries to hit 10 units, and if
                it can't, it constantly hits the available targets in a cycle.
                On a Solo Boss, her burst can target the same single enemy and
                do big numbers every second the DoT procs. However, when there
                are more enemies available, she does the same amount of damage,
                but it gets diluted (similar to how distributed damage becomes
                diluted the more enemies are on the screen). Unfortunately, in
                the case of SSakura, she does not hit as hard as SBS, not even
                close if we are being honest. Additionally, due to her not
                having inherent ATK buffs, her performance starts lagging behind
                in High Deficit Campaign as well. As a result of all this, her
                damage gets extremely diluted, making her an extremely hard unit
                to use for Campaign.
              </p>
              <h5>
                Boss (Solo) - <strong className="ss">SS</strong> | Boss (Adds) -{' '}
                <strong className="s">S</strong>
              </h5>
              <p>
                S. Sakura's domain is against Bosses. If we go into specifics,
                it is against Bosses that either have abundance of parts (that
                regularly respawn), or are weak to the Wind Element. She can
                easily outshine most prominent units, such as Scarlet. SSakura
                performs exceptionally well against single targets, and can
                prove to be a competitor of none other than our well known
                Queen, Modernia! If you play on a lower FPS, she will easily
                outshine Modernia. However, in a 60 FPS, neutral element
                environment, against a Boss that has no parts but has a core,
                Modernia will dominate SSakura. But.. the more you start going
                in favour of SSakura, the closer, if not better, SSakura starts
                to become! If you add Boss Parts, she gets closer in damage, and
                if you happen to make that Boss Wind-Weak, she comes even
                closer! Mix both of them, and at her peak, SSakura should be
                able to edge out Modernia in DPS! Against Bosses with Adds, she
                is still a strong pick, but her usability is comparatively lower
                due to her damage getting because of the smaller raptures
                present on the screen.
              </p>
              <p>Damage Comparison vs Scarlet Against Solo Bosses:</p>
              <StaticImage
                src="../../../images/nikke/reviews/sakura_2.webp"
                alt="Kit"
              />
              <h5>
                PVP - <strong className="ss">SS</strong>
              </h5>
              <p>
                S. Sakura is a strong choice in PvP as a main B3. Contrary to
                her use in PvE as a DoT unit, she will shine in PvP as a Nuker!
                In the showcase video below, you can see how strong her nuke is.
                However, this does not show the downside of using her. Her
                ~4000% nuke happens to be divided into 10 shots over 2.2
                seconds, and the targets each individual ~400% part of the nuke
                in a random order, one by one. An advantage of this is that her
                nuke cannot be taunted onto one target, and instead moves in a
                cycle which hits all 5 units. The worst part about this is the
                fact that her Nuke is divided into 5 units. However, some things
                that go in favour of SSakura are the facts that her damage that
                comes from her Skill 2 is of high value and targets the Highest
                ATK unit of the opponents. If the nuke hits the Highest ATK unit
                fast, paired with the S2 damage, it is a confirmed kill! Not
                only that, she scales well with ATK buffs, and also does DoT on
                the units targeted by her nuke, so anyone left on low HP is also
                guaranteed a sure fire death!
              </p>
              <p>
                The major counter to S. Sakura will be Noah, who can completely
                shut-down Sakura's Nuke, and as a result, shuts the window on
                any plan you had on beating the opponent team. Scarlet is also a
                decent opponent against SSakura, since she is AR, and also does
                damage over time to Scarlet herself. This goes in favour of
                Scarlet due to her own Skill 2, which has a chance of
                counterattacks which do deathly damage in PvP.{' '}
              </p>
              <Row className="video">
                <Col xs={12} lg={6}>
                  <YoutubeEmbed embedId="m9oSxMM9xqw" />
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-3">
              <SectionHeader title="Investments" />
              <h5>Gear Investments</h5>
              <p>
                Sakura's OL requirements are relatively simple. Just ATK and
                Elemental DMG (staple for DPS). Nothing else. She is not reliant
                on Max Ammo. However, we discovered her DoT can CRIT, so CRIT
                lines are passable.
              </p>
              <ul>
                <li>
                  <strong>Essential:</strong> -
                </li>
                <li>
                  <strong>Ideal:</strong> 4× Elemental DMG and 4× ATK
                </li>
                <li>
                  <strong>Passable:</strong> 4× CRIT Rate, 4× CRIT DMG
                </li>
                <li>
                  <strong>Priority:</strong> Medium
                </li>
              </ul>
              <h5>Skill Investments</h5>
              <ul>
                <li>
                  <strong>Skill 1: 5-7</strong>
                </li>
                <ul>
                  <li>
                    At level 5, breaking 2 parts every 30 seconds can provide
                    permanent uptime for her Skill 2. However, you may upgrade
                    it to level 7 for the minor extra sustained damage buff, as
                    well as to maximize uptime when there is only 1 part break
                    every 30 seconds.
                  </li>
                </ul>
                <li>
                  <strong>Skill 2: 7~10</strong>
                </li>
                <ul>
                  <ul>
                    <li>
                      Skill 2 is where most of her DPS output comes from.
                      Eventually max out this skill to take full benefit of the
                      Attack Damage Buff, as well as the DoT skill.
                    </li>
                  </ul>
                </ul>
                <li>
                  <strong>Burst Skill: 7~10</strong>
                </li>
                <ul>
                  <li>
                    Her Burst Skill is also a major chunk of her damage output.
                    It especially comes into play in PvP. Apart from that, this
                    skill also has a DoT, which adds substantial damage.
                    Eventually max out this skill as well to take full advantage
                    of her skills.
                  </li>
                </ul>
              </ul>
              <h5>Suggested Progression Path</h5>
              <div className="nikke-skills-tips">
                <div className="nikke-skills-row header">
                  <div className="column character">&nbsp;</div>
                  <div className="column suggestions pve">PVE Investment</div>
                  <div className="column suggestions pvp">PVP Investment</div>
                  <div className="column priority">Priority</div>
                </div>
                <div className="nikke-skills-row data">
                  <NikkeSkill char="sakura-bloom-in-summer" />
                </div>
              </div>
              <br />
              <h5>Cube Investments</h5>
              <StaticImage
                src="../../../images/nikke/generic/cube_4_sm.png"
                alt="Guide"
              />
              <p>
                S. Sakura does not particularly require any cube. Any generic
                cube works on her, but Bastion will allow her to shoot more
                bullets over 3 minutes. Despite that, it is recommended to use
                the highest level cube available for the ATK (and Elemental DMG)
                bonus.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-4">
              <SectionHeader title="PvE Team Compositions" />
              <h5>Team #1: The Anticipated Synergy</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="rosanna-chic-ocean"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="helm" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                S. Rosanna buffs S. Sakura extremely well. Her Damage Taken
                debuff, paired with extra Parts Damage buffs, as well as the
                Sustained Damage buff are all highly appreciated. Combined, they
                allow S. Sakura to deal immense damage to opponents. This buffs
                S. Sakura beyond what most other teams can, even higher than the
                famous bunny duo!
              </p>
              <h5>Team #2: The Bunny Duo</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                    <NikkeCharacter mode="icon" slug="volume" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="noir" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="modernia" enablePopover />
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                While Tia's Attack Damage buff and Naga's ATK buff are highly
                appreciated, Tia and Naga are core-hit buffers and are better
                suited to units that hit the core more often. However, S.
                Sakura's passive damage comes from skills instead of core hits.
                On the other hand, Blanc and Noir help S. Sakura the same as
                they would benefit any other unit. Maxwell boosts S. Sakura's
                output, while Modernia in general performs exceptionally well in
                the bunny team due to Noir's Max Ammo buffs.
              </p>
              <h5>Team #3: All in on Sakura!</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="crown" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="maxwell" enablePopover />
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="alice-wonderland-bunny"
                      enablePopover
                    />
                  </div>
                </div>
              </div>
              <p>
                This team aims to maximise S. Sakura's damage as a Main DPS.
                This is definitely not the ideal team to run since better DPS
                like Alice and SBS would benefit more from Crown. However, if
                you lack prominent DPS units, or are a super fan of SSakura,
                this team is a possible combination. You may also run this in
                campaign if you lack the big DPS pilgrims.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-5">
              <SectionHeader title="PvP Team Compositions" />
              <h5>Team #1: High Power Nuke (3RL)</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="red-hood" enablePopover />
                    <NikkeCharacter mode="icon" slug="liter" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Opponent running a slow team? This team can effectively counter
                it. Can also counter Blanc + Emilia teams well if this team is
                run with Blanc. The Blanc version of this team will fare will
                against most Noah-less teams.
              </p>
              <h5>Team #2: Making Use of DoT with Stall (3RL)</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="rapunzel" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="placeholder-box">Clip-SG</div>
                </div>
              </div>
              <p>
                Noir, B.Soda, and Drake are the best choices for Clip SG here.
                Hits less harder as the previous team but you save some critical
                resources. Again, will fare well against Noah-less teams, but
                otherwise may struggle.
              </p>
              <h5>Team #3: Fast Nuke (2~2.5RL)</h5>
              <div className="nikke-team-showcase">
                <div className="team-slot">
                  <h5 className="burst-3">Burst 3</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter
                      mode="icon"
                      slug="sakura-bloom-in-summer"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="blanc" enablePopover />
                    <NikkeCharacter
                      mode="icon"
                      slug="miracle-fairy-anne"
                      enablePopover
                    />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-flex">Burst Flex</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="emilia" enablePopover />
                    <NikkeCharacter mode="icon" slug="anis" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-2">Burst 2</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="centi" enablePopover />
                  </div>
                </div>
                <div className="team-slot">
                  <h5 className="burst-1">Burst 1</h5>
                  <div className="employee-container for-nikke">
                    <NikkeCharacter mode="icon" slug="jackal" enablePopover />
                  </div>
                </div>
              </div>
              <p>
                Opponent running a slow team? This team can effectively counter
                it. Can also counter Blanc + Emilia teams well if this team is
                run with Blanc. The Blanc version of this team will fare will
                against most Noah-less teams.
              </p>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
            <div className="section-scroll" id="section-6">
              <SectionHeader title="Pros & Cons" />
              <Row className="pros-cons">
                <Col sm="12" md="6">
                  <div className="box pros">
                    <h5>Pros</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>Scales really well with ATK buffs.</li>
                        <li>
                          Stable DPS yet packs a lot of punch during her own
                          Burst.
                        </li>
                        <li>
                          Consistent Attack Damage buff to pair with her S2
                          activation.
                        </li>
                        <li>
                          Nuke and follow-up DoT can be effectively used in PvP,
                          and are a strong source of damage in PvE as well.
                        </li>
                        <li>
                          Competes for a spot in Solo Raids against Wind Weak
                          Bosses.
                        </li>
                        <li>Somehow, she is even cuter than before!</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col sm="12" md="6">
                  <div className="box cons">
                    <h5>Cons</h5>
                    <hr />
                    <div className="raw list">
                      <ul>
                        <li>
                          Against multiple enemies, her Burst's effectiveness
                          falls off.
                        </li>
                        <li>No innate ATK buff.</li>
                        <li>
                          Since she wields AR, she may not be able to
                          effectively hit parts against far enemies, and hence
                          her self Parts Damage buff will be a waste.
                        </li>
                        <li>
                          If neither Boss Parts and Element are in her favor,
                          she may not be able to compete in damage against Meta
                          B3 in Raids, but we shall see later after Rosanna is
                          here.
                        </li>
                        <li>
                          In PvP, her DoT and AR weapon type put her at a
                          disadvantage against Scarlet+Jackal teams, since she
                          can be highly susceptible to dying against Scarlet's
                          Skill 2.
                        </li>
                      </ul>
                    </div>
                  </div>
                </Col>
              </Row>
              <div
                className="fuse-ad-placeholder"
                data-fuse="22844297238"
              ></div>
            </div>
          </div>
          <div className="right-sidebar with-sticky">
            <ScrollspyNav
              scrollTargetIds={[
                'section-0',
                'section-1',
                'section-2',
                'section-3',
                'section-4',
                'section-5',
                'section-6'
              ]}
              activeNavClass="is-active"
              scrollDuration="500"
              headerBackground="true"
              offset={100}
            >
              <h6>Jump to</h6>
              <ul className="list-unstyled">
                <li>
                  <a href="#section-0">Introduction</a>
                </li>
                <li>
                  <a href="#section-1">Kit Analysis</a>
                </li>
                <li>
                  <a href="#section-2">Tier List</a>
                </li>
                <li>
                  <a href="#section-3">Investments</a>
                </li>
                <li>
                  <a href="#section-4">PVE Team Compositions</a>
                </li>
                <li>
                  <a href="#section-5">PVP Team Compositions</a>
                </li>
                <li>
                  <a href="#section-6">Pros & Cons</a>
                </li>
              </ul>
            </ScrollspyNav>
            <div className="fuse-ad-placeholder" data-fuse="22844297250"></div>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesSakuraSummer;

export const Head: React.FC = () => (
  <Seo
    title="Sakura: Bloom in Summer guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Sakura: Bloom in Summer in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
